import { FileFilter } from '.';

/* Account */
export interface Permissions {
  canApproveCreatorApplications: boolean;
  canFeatureLists: boolean;
  canPostContent: boolean;
  canModerateContent: boolean;
}

export interface Restrictions {
  cannotPostAnything: boolean;
  cannotPostComments: boolean;
  cannotPostContent: boolean;
  cannotModerate: boolean;
}

export interface Session {
  user: User;
  token: string;
}

export interface SubscriptionPriceHistory {
  activeStarting: Date;
  activeUntil: Date;
  isActive: boolean;
  subscriptionPrice: number;
  user: User;
}

export interface User {
  __typename: string;
  id: string;
  postCount: number;
  commentCount: number;
  likeCount: number;
  curatedPostCount: number;
  curatedListCount: number;
  curatedListFollowingCount: number;
  displayName?: string;
  hasSubmittedCreatorApplication: boolean;
  pronouns?: string;
  purchaseCount: number;
  subscribedCount: number;
  subscriberCount: number;
  subscriptionPostCount: number;
  subscriptionPrice: number;
  unseenNotificationCount: number;
  userFollowerCount: number;
  userFollowingCount: number;
  username: string;
  userHasFollowed: boolean;
  CreatorApplications: CreatorApplication[];
  avatar: Image;
  comments: Comment[];
  credits: Credit[];
  curatorialMemberships: CuratorialMembership[];
  permissions: Permissions;
  profile: Profile;
  restrictions: Restrictions;
  activeSubscriptionPackages: SubscriptionDiscount[];
  activeUserSubscriptions: UserSubscription[];
}

/* CMS */

export enum PostAccessType {
  'post-access-type-free',
  'post-access-type-subscription',
  'post-access-type-pay',
}

export interface Post {
  __typename: string;
  id: string;
  insertedAt: Date;
  deletedAt: Date;
  publishAt: Date;
  accessType: PostAccessType;
  likeCount: number;
  curatedListCount: number;
  description: string;
  imageCount: number;
  isApproved: boolean;
  price: number;
  commentCount: number;
  purchaseCount: number;
  title: string;
  topLevelCommentCount: number;
  videoCount: number;
  videoDuration: number;
  userIsCredited: boolean;
  userHasLiked: boolean;
  userHasPurchased: boolean;
  credits: Credit[];
  curatedPostListings: CuratedPostListing[];
  features: Feature[];
  mediaCollection: MediaCollection;
  previewVideo: Video;
  users: User[];
}

export enum Citizenship {
  'citizenship-none',
  'citizenship-usa',
}

export interface CreatorApplication {
  id: string;
  birthday: Date;
  citizenship: Citizenship;
  legalName: string;
  user: User;
}

export interface Comment {
  __typename: string;
  id: string;
  commentCount: number;
  insertedAt: Date;
  deletedAt: Date;
  text: string;
  post: Post;
  children: Comment[];
  user: User;
  parent: Comment;
}

export enum Role {
  'performer',
  'photographer',
  'videographer',
  'other',
}

export interface Credit {
  id: string;
  canModify: boolean;
  isPublisher: boolean;
  isVerified: boolean;
  role: Role;
  share: number;
  title?: string;
  post: Post;
  user: User;
}

/* Curation */

export interface CuratedPostListing {
  id: string;
  post: Post;
  curatedList: CuratedList;
  user: User;
}

export interface CuratedList {
  __typename: string;
  id: string;
  curatedPostCount: number;
  description?: string;
  userFollowerCount: number;
  isFeatured: boolean;
  name: string;
  userHasFollowed: boolean;
  curatedPostListings: CuratedPostListing[];
  curatorialMemberships: CuratorialMembership[];
}

export interface CuratorialMembership {
  id: string;
  isOwner: boolean;
  curatedList: CuratedList;
  user: User;
}

export interface Feature {
  id: string;
  post: Post;
  curatedList: CuratedList;
  user: User;
}

/* Discovery */

export interface Tag {
  id: string;
  name: string;
  discoverableCount: number;
}

/* Media */

export interface MediaCollection {
  id: string;
  mediaItems: [MediaItem];
  post?: Post;
}

export interface MediaItem {
  __typename: string;
  id: string;
  previewable: boolean;
  order: number;
  image?: Image;
  mediaCollection: MediaCollection;
  video?: Video;
}

export interface Image {
  id: string;
  caption: string;
  extension: string;
  zoomIntensity: number;
  zoomX: number;
  zoomY: number;
  previewSrc?: string;
  previewType: FileFilter;
  previewUploadUrl: string;
  src?: string;
  uploadUrl: string;
}

export interface Video {
  id: string;
  caption: string;
  extension: string;
  zoomIntensity: number;
  zoomX: number;
  zoomY: number;
  previewSrc?: string;
  previewType: FileFilter;
  previewUploadUrl: string;
  src?: string;
  uploadUrl: string;
}

/* Membership */

export interface FeedItem {
  __typename: string;
  id: string;
  type: number;
  post: Post;
  curatedList: CuratedList;
  user: User;
}

export interface Profile {
  id: string;
  description?: string;
  website?: string;
  user: User;
}

export enum SubscriptionDiscountType {
  'initialSubscription',
  'twoMonthPackage',
  'threeMonthPackage',
}

export enum SubscriptionDiscountValidSubscriberType {
  'firstTime',
  'returning',
  'either',
}

export interface SubscriptionDiscount {
  id: string;
  activeStarting: Date;
  activeUntil: Date;
  isActive: boolean;
  percentage: number;
  type: SubscriptionDiscountType;
  validSubscriberType: SubscriptionDiscountValidSubscriberType;
}

export enum UserSubscriptionType {
  'purchased',
  'granted',
}

export interface UserSubscription {
  id: string;
  activeStarting: Date;
  activeUntil: Date;
  autorenew: boolean;
  mustConfirmAutorenew: boolean;
  totalPaid: number;
  type: UserSubscriptionType;
  subscriptionDiscount: SubscriptionDiscount;
  subscriptionPriceHistory: SubscriptionPriceHistory;
  subscriber: User;
  subscribed: User;
}

/* Moderation */

export enum ModerationType {
  'creatorApplication',
  'collectiveApplication',
  'post',
  'curatedList',
  'profile',
  'comment',
  'postApproval',
}

export interface ModerationRequest {
  __typename: string;
  id: string;
  isResolved: boolean;
  moderationRequestReportCount: number;
  moderationRequestResponseCount: number;
  sortedAt: Date;
  type: ModerationType;
  post: Post;
  creatorApplication: CreatorApplication;
  comment: Comment;
  curatedList: CuratedList;
  moderationRequestReports: ModerationRequestReport[];
  moderationRequestResponses: ModerationRequestResponse[];
  profile: Profile;
}

export interface ModerationRequestReport {
  __typename: string;
  id: string;
  comment: string;
  insertedAt: Date;
  isResolved: boolean;
  moderationRequest: ModerationRequest;
  user: User;
}

export interface ModerationRequestResponse {
  __typename: string;
  id: string;
  comment: string;
  hasConfirmed: boolean;
  insertedAt: Date;
  shouldRemove: boolean;
  moderationRequest: ModerationRequest;
  user: User;
  moderationRequestResponseFieldRevisions: ModerationRequestResponseFieldRevision[];
  moderationRequestResponseMediaItemActions: ModerationRequestResponseMediaItemAction[];
  moderationRequestResponseUserActions: ModerationRequestResponseUserAction[];
}

export interface ModerationRequestResponseFieldRevision {
  id: string;
  field: string;
  original: string;
  revision: string;
}

export interface ModerationRequestResponseMediaItemAction {
  id: string;
  shouldDelete: boolean;
  shouldReport: boolean;
  mediaItem: MediaItem;
}

export interface ModerationRequestResponseUserAction {
  id: string;
  activeUntil: Date;
  cannotPostAnything: boolean;
  cannotPostComments: boolean;
  cannotPostContent: boolean;
  cannotModerate: boolean;
  user: User;
}

export interface UserRestriction {
  id: string;
  activeUntil: Date;
  cannotPostAnything: boolean;
  cannotPostComments: boolean;
  cannotPostContent: boolean;
  cannotModerate: boolean;
}

/* Notifications */

export interface Notification {
  __typename: string;
  id: string;
  isRead: boolean;
  isSeen: boolean;
  sortedAt: Date;
  type: number;
  count: number;
  post: Post;
  comment: Comment;
  curatedList: CuratedList;
  latestUser: User;
  moderationRequestResponse: ModerationRequestResponse;
  user: User;
  userRestriction: UserRestriction;
}
