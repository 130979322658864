import React, { ReactElement } from 'react';
import FileUploaderQueue from 'src/components/file-uploader/queue';
import { FieldProps, FieldArray } from 'formik';
import {
  ImageInput,
  MediaItemInput,
  VideoInput,
  FileUpload,
} from 'src/types/input';
import { StyledFieldError } from 'src/styles';

const validateFileUpload = (file: FileUpload): boolean => {
  return file.type === 'image' || file.type === 'video';
};
const convertToMediaItem = (
  index: number,
  file: FileUpload
): MediaItemInput => {
  return file.type === 'image'
    ? {
        id: file.mediaItemId,
        order: index,
        previewable: file.previewable ?? false,
        fileUpload: file,
        image: {
          id: file.id,
          caption: file.caption,
          file: file.modifiedFile,
          zoomIntensity: Math.round(file.zoomIntensity ?? 0),
          zoomX: Math.round(file.zoomX ?? 50),
          zoomY: Math.round(file.zoomY ?? 50),
          filteredModifiedFile: file.filteredModifiedFile,
          previewSrc: file.previewSrc,
          previewType: file.previewType,
        } as ImageInput,
      }
    : {
        id: file.mediaItemId,
        order: index,
        previewable: file.previewable ?? false,
        fileUpload: file,
        video: {
          id: file.id,
          caption: file.caption,
          duration: Math.round(file.modifications.duration || 0),
          file: file.modifiedFile,
          filteredModifiedFile: file.filteredModifiedFile,
          previewSrc: file.previewSrc,
          previewType: file.previewType,
        } as VideoInput,
      };
};

const validateAndConvert = (
  index: number,
  fileUpload: FileUpload,
  cb: (index: number, mediaItem: MediaItemInput) => void
): boolean => {
  if (validateFileUpload(fileUpload)) {
    const mediaItem = convertToMediaItem(index, fileUpload);
    cb(index, mediaItem);

    return true;
  } else {
    return false;
  }
};

interface InterfaceProps {
  mode?: string;
}

const MediaCollectionUploaderView: React.FC<InterfaceProps & FieldProps> = ({
  field,
  form,
  mode,
}) => {
  const mediaCollection = form.values[field.name];
  const mediaItems = mediaCollection.mediaItems as MediaItemInput[];

  return (
    <>
      <FieldArray name="mediaCollection.mediaItems">
        {(arrayHelpers): ReactElement => {
          const files = mediaItems
            .map((mi): FileUpload | undefined => mi.fileUpload)
            .filter((fu) => fu) as FileUpload[];
          const insert = (index: number, fileUpload: FileUpload): boolean => {
            return validateAndConvert(
              index,
              fileUpload,
              (index: number, mediaItem: MediaItemInput) => {
                arrayHelpers.insert(index, mediaItem);
              }
            );
          };

          const remove = (index: number): void => {
            arrayHelpers.remove(index);
          };

          const replace = (index: number, fileUpload: FileUpload): boolean => {
            return validateAndConvert(
              index,
              fileUpload,
              (index: number, mediaItem: MediaItemInput) => {
                arrayHelpers.replace(index, mediaItem);
              }
            );
          };

          const swap = (indexA: number, indexB: number): void => {
            mediaItems[indexA].order = indexB;
            mediaItems[indexB].order = indexA;
            arrayHelpers.swap(indexA, indexB);
          };

          return (
            <FileUploaderQueue
              files={files}
              mode={mode}
              insert={insert}
              remove={remove}
              replace={replace}
              swap={swap}
            />
          );
        }}
      </FieldArray>
      <StyledFieldError name="mediaCollection.mediaItems" component="div" />
    </>
  );
};

export default MediaCollectionUploaderView;
